<template>
  <div>
    <div
      variant="light"
      class="justify-content-end container alert alert-white alert-shadow"
      style="padding-top: 10px"
    >
      <div class="row">
        <b-col md="3">
          <b-container fluid>
            <b-col><label>Tìm kiếm :</label></b-col>
            <b-col>
              <b-form-input
                v-model="searchData.phone"
                placeholder="SĐT cách nhau bởi dấu ,"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label>Loại tài khoản :</label></b-col>
            <b-col>
              <b-form-select v-model="searchData.accountType">
                <option value="1">TK doanh nghiệp</option>
                <option value="3">TK cá nhân</option>
              </b-form-select>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label>Loại ví :</label></b-col>
            <b-col>
              <b-form-select v-model="searchData.balanceType">
                <option value="1">Ví thường (main)</option>
                <option value="2">Ví thưởng (promotion)</option>
              </b-form-select>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label>TK phát sinh giao dịch:</label></b-col>
            <b-col>
              <b-form-select v-model="searchData.haveTransaction">
                <option value="ALL">Tất cả</option>
                <option value="1">Có</option>
                <!--                <option value="0">Không</option>-->
              </b-form-select>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label :for="`startDate`">Ngày bắt đầu :</label></b-col>
            <b-col>
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="searchData.startDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
        <b-col md="3">
          <b-container fluid>
            <b-col><label :for="`endDate`">Ngày kết thúc :</label></b-col>
            <b-col>
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="searchData.endDate"
              ></b-form-input>
            </b-col>
          </b-container>
        </b-col>
      </div>
      <div class="row col-md-12 justify-content-end">
        <button
          ref="submit-search"
          class="
            btn btn-elevate btn-primary
            font-weight-bold
            px-11
            py-2
            my-4
            font-size-3
          "
          @click="search()"
        >
          Search
        </button>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Số dư ví các tài khoản theo kỳ</h6>
              </template>
              <b-table
                ref="table"
                striped
                hover
                responsive
                caption-top
                :busy="tableLoading"
                :items="items"
                :fields="fields"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>...</strong>
                  </div>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import Message from "@/core/config/message.config";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
const StatsRepository = RepositoryFactory.get("stats");
const AccountRepository = RepositoryFactory.get("account");

export default {
  name: "AccountBalanceDaily",
  mixins: [Common],
  components: {},
  data() {
    return {
      searchData: {
        accountType: 3, // Tài khoản cá nhân
        balanceType: 1, // Main Account
        haveTransaction: "ALL",
        startDate: this.getYesterday(),
        endDate: this.getCurrentDay()
      },

      fields: [
        {
          key: "phone",
          label: "Tài khoản"
        },
        {
          key: "balance_start",
          label: "Số dư đầu kỳ",
          formatter: value => value.toLocaleString()
        },
        {
          key: "balance_end",
          label: "Số dư cuối kỳ",
          formatter: value => value.toLocaleString()
        },
        {
          key: "balance_increment",
          label: "Phát sinh tăng",
          formatter: value => value.toLocaleString()
        },
        {
          key: "balance_decrement",
          label: "Phát sinh giảm",
          formatter: value => value.toLocaleString()
        },
        {
          key: "balance_change",
          label: "Thay đổi",
          formatter: value => value.toLocaleString()
        },
        {
          key: "trans_total",
          label: "Số GD"
        },
        {
          key: "payment_total",
          label: "Số GD TT"
        }
      ],
      items: [],
      tableLoading: false,
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0
      },
      sumTotalAccounts: {
        id: "total",
        phone: "Total",
        trans_total: 0,
        payment_total: 0,
        balance_change: 0,
        balance_start: 0,
        balance_end: 0,
        balance_increment: 0,
        balance_decrement: 0
      }
    };
  },
  methods: {
    async getPeriodBalance() {
      this.items = [];

      this.getWalletPeriodBalance();
      if (!this.searchData.phone || this.searchData.phone == undefined) {
        this.sumAccountPeriodBalance();
      }
      await this.getAccounts();
      this.getAccountsPeriodBalace();
    },

    async sumAccountPeriodBalance() {
      const params = {
        start_date: this.searchData.startDate,
        end_date: this.searchData.endDate,
        balance_type: this.searchData.balanceType,
        account_type: this.searchData.accountType
      };

      const response = await StatsRepository.sumAccountPeriodBalance(params);
      const body = response.data;
      let sumTotalClone = this.observerToData(this.sumTotalAccounts);
      sumTotalClone.balance_start = body.data.balance_start;
      sumTotalClone.balance_end = body.data.balance_end;
      sumTotalClone.balance_increment = body.data.balance_increment;
      sumTotalClone.balance_decrement = body.data.balance_decrement;
      sumTotalClone.balance_change = body.data.balance_change;
      sumTotalClone.trans_total = body.data.trans_total;
      sumTotalClone.payment_total = body.data.payment_total;
      this.items.unshift(sumTotalClone);
    },

    async getWalletPeriodBalance() {
      try {
        const params = {
          start_date: this.searchData.startDate,
          end_date: this.searchData.endDate,
          balance_type: this.searchData.balanceType,
          account_type: this.searchData.accountType
        };

        const response = await StatsRepository.getWalletPeriodBalance(params);
        const body = response.data;
        let walletBalance = {
          id: "wallet",
          phone: "Wallet Total",
          trans_total: "-",
          payment_total: "-",
          balance_change: body.data.balance_change,
          balance_start: body.data.balance_start,
          balance_end: body.data.balance_end,
          balance_increment: body.data.balance_increment,
          balance_decrement: body.data.balance_decrement
        };
        this.items.unshift(walletBalance);
      } catch (e) {
        return this.showError(Message.ERROR.SERVER);
      }
    },

    async getAccounts() {
      try {
        this.tableLoading = true;
        let params = {
          page: this.currentPage,
          limit: this.paginate.limit,
          balance_type: this.searchData.balanceType,
          account_type: this.searchData.accountType,
          have_transaction: this.searchData.haveTransaction,
          start_date: this.searchData.startDate,
          end_date: this.searchData.endDate
        };
        if (this.searchData.phone) {
          params.phone = this.searchData.phone;
        }

        const response = await AccountRepository.getListPhone(params);

        let body = response.data;
        this.items.push(...body.data.list);
        this.paginate.total = body.data.paging.total;
        this.paginate.totalPage = body.data.paging.total_page;
      } catch (e) {
        this.tableLoading = false;
        return this.showError(Message.ERROR.SERVER);
      }
    },

    async getAccountsPeriodBalace() {
      try {
        const params = {
          account_ids: this.getArrayOfAccountIds(this.items),
          start_date: this.searchData.startDate,
          end_date: this.searchData.endDate,
          balance_type: this.searchData.balanceType,
          account_type: this.searchData.accountType
        };
        const response = await StatsRepository.getAccountsPeriodBalace(params);
        this.tableLoading = false;
        const data = response.data.data;
        this.updateAccountBalance(data);
      } catch (e) {
        this.tableLoading = false;
        return this.showError(Message.ERROR.SERVER);
      }
    },

    updateAccountBalance(data) {
      let sumTotalClone = this.observerToData(this.sumTotalAccounts);
      [...this.items].forEach(item => {
        if (item.id == "wallet" || item.id == "total") {
          return;
        }
        [...data].forEach(balance => {
          if (item.id == balance.account_id) {
            item.balance_change = balance.balance_change;
            item.balance_start = balance.balance_start;
            item.balance_end = balance.balance_end;
            item.balance_increment = balance.balance_increment;
            item.balance_decrement = balance.balance_decrement;
            item.trans_total = balance.trans_total;
            item.payment_total = balance.payment_total;
            if (this.searchData.phone) {
              sumTotalClone.trans_total += balance.trans_total;
              sumTotalClone.payment_total += balance.payment_total;
              sumTotalClone.balance_start += balance.balance_start;
              sumTotalClone.balance_end += balance.balance_end;
              sumTotalClone.balance_change += balance.balance_change;
              sumTotalClone.balance_increment += balance.balance_increment;
              sumTotalClone.balance_decrement += balance.balance_decrement;
            }
          }
        });
      });
      if (this.searchData.phone) {
        this.items.unshift(sumTotalClone);
      }
      this.$refs.table.refresh();
    },

    getArrayOfAccountIds(data) {
      let ids = [];
      data.forEach(item => {
        ids.push(item.id);
      });
      return ids;
    },

    async search() {
      this.resetPaginate();
      this.getPeriodBalance();
    },

    resetPaginate() {
      this.currentPage = 1;
      this.paginate.limit = 50;
    }
  },
  watch: {
    currentPage() {
      this.getPeriodBalance();
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Số dư ví theo kỳ" }]);
    this.getPeriodBalance();
  }
};
</script>
